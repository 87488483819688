<template>
    <v-row class="acceptConditions">
        <v-col cols="12">
            <h1>Betingelser for brug af Norfors' genbrugspladser i ubemandet åbningstid:</h1>
        </v-col>
        <v-col cols="12">
            &bull; Det er muligt at benytte den udvidede åbningstid på følgende pladser: <br>
            <ul>
                <li>Genbrugsplads Allerød</li>
                <li>Genbrugsplads Bakkegårdsvej</li>
                <li>Genbrugsplads Blokken</li>
                <li>Genbrugsplads Containerhaven</li>
                <li>Genbrugsplads Vandtårnsvej</li>
            </ul>
        </v-col>
        <v-col cols="12">
            &bull; Det er nummerpladen på din bil, som giver adgang til genbrugspladsen i den ubemandede åbningstid. Det sker vha. kameraer med nummerpladeskannere, som sørger for at åbne og lukke portene.
        </v-col>
        <v-col cols="12">
            &bull; Genbrugspladsen er videoovervåget.
        </v-col>
        <v-col cols="12">
            &bull; Da den udvidede åbningstid er ubemandet, skal du selv sørge for korrekt sortering af affaldet. Er du i tvivl, kan du bruge <a href="https://norfors.dk/borger/sorteringsvejledning/">Norfors' sorteringsguide</a> eller komme indenfor den bemandede åbningstid
        </v-col>
        <v-col cols="12">
            &bull; Bevidst eller ubevidst fejlsortering af affald, klunsning og/eller hærværk, kan resultere i udelukkelse fra selvbetjeningsordningen samt erstatningskrav og/eller politianmeldelse
        </v-col>
        <v-col cols="12">
            &bull; Følgende affald modtages ikke i den ubemandede åbningstid: <br>
            <ul>
                <li>Farligt affald (fraktion 60)</li>
                <li>Eternit og ikke-støvende asbest (fraktion 31)</li>
                <li>Deponi (fraktion 32)</li>
                <li>Elektronikaffald, herunder: <br>
                    <ul>
                        <li>Akkumulatorer/batterier (fraktion 48)</li>
                        <li>Kabler (fraktion 49)</li>
                        <li>Små husholdningsapparater (fraktion 52)</li>
                        <li>Skærme og monitorer (fraktion 53)</li>
                        <li>Mellemstore husholdningsapparater (fraktion 54)</li>
                        <li>Batterier (fraktion 62)</li>
                        <li>Lyskilder (fraktion 63)</li>
                        <li>Printertoner og blækpatroner (fraktion 64)</li>
                    </ul>
                </li>
            </ul>
        </v-col>
        <v-col cols="12">
            &bull; Ordensregler for brug af Norfors' genbrugspladser skal overholdes. <br>  
            &nbsp;&nbsp;Se ordensreglerne hér: <br>
            &nbsp;&nbsp;<a href="https://norfors.dk/borger/ordensregler/" target="_blank">https://norfors.dk/borger/ordensregler/</a>
        </v-col>
        <v-col cols="12">
            <v-card
                color="#ECECEC"
                style="align-items: center;"
            >
                <v-checkbox
                    v-model="checkboxAccept"
                    label="Jeg accepterer ovenstående betingelser."
                />
            </v-card>
        </v-col>
        <v-col cols="12">
            <v-btn
                :disabled="!checkboxAccept"
                color="#75ad40"
                @click="$emit('acceptConditions')"
            >
                Accepter
            </v-btn>
        </v-col>
    </v-row>
</template>


<script>

export default {
    data() {
        return {
            checkboxAccept: false
        }
    }
}

</script>